<template>
  <v-sheet>
    <v-autocomplete
      v-bind="props"
      item-text='name'
      item-value='id'
      :items="channels"
      @change="change"
    >
      <template v-slot:item="{ item }">
        <v-list-item-avatar v-if="favChannels.some(x=>x===item.id)"><v-icon small color="primary">{{ mdiStar }}</v-icon></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>{{Math.floor(item.participants / 1000).toLocaleString() }}к, посл закуп: {{getDaysSinceLastAd(item)}}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:selection="{item}">
        {{item.name}}
          <v-chip class="success" text-color="white">
            {{Math.floor(item.participants / 1000).toLocaleString() }} К
          </v-chip>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="grey--text text--darken-1">
              Нет каналов
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <div v-if="selectedChannel">
      <v-btn
        v-if="selectedChannel.tgstat_link"
        :href="selectedChannel.tgstat_link"
        target="_blank"
        color="primary"
        text small
      >
        TGS
      </v-btn>
      <v-btn v-if="selectedChannel.telemetr_link" :href="selectedChannel.telemetr_link" target="_blank" color="primary" text small>
        TLM
      </v-btn>
      <v-btn v-if="selectedChannel.admin_contact" :href="'https://t.me/'+selectedChannel.admin_contact.replace('@', '')" target="_blank" color="primary" text small>
        Админ
      </v-btn>

<!--      <a v-if="selectedChannel.telemetr_link" :href="selectedChannel.telemetr_link">TLM</a>-->
    </div>
  </v-sheet>

</template>

<script>
import {computed, ref} from '@vue/composition-api'
import store from '@/store'
import { mdiStar } from '@mdi/js'

export default {
  name: 'AdChannelSelector',
  props: {
    adChannelId: {type: Number, default: 0},
    items: {type: Array, default: () => []},
    label: {type: String, default: 'Источник'},
    clearable: {type: Boolean, default: true},
    outlined: {type: Boolean, default: true},
    dense: {type: Boolean, default: false},
    itemText: {type: String, default: 'name'},
    itemValue: {type: String, default: 'id'},
    multiple: {type: Boolean, default: false},
    chips: {type: Boolean, default: false},
    deletableChips: {type: Boolean, default: false},
    returnObject: {type: Boolean, default: false},
    hideDetails: {type: Boolean, default: true},
  },
  setup(props, {emit}) {
    const favChannels = computed(() => store.state.favChannels)
    const channels = computed(() => store.state.adChannels.filter(x=>x.is_active).sort((a, b) => (favChannels.value.some(x=>x===a.id) ? -1 : 1)))
    const channel_id = ref(0)
    if (props.adChannelId) channel_id.value = props.adChannelId
    const selectedChannel = computed(() => channels.value.find(x=>x.id===channel_id.value))

    const change = (val) => {
      emit('change', val)
      emit('update:value', val)
      emit('input', val)
      console.log('change', val)
      channel_id.value = val
    }

    const getDaysSinceLastAd = channel => {
      if (!channel.publications.length) return 'нет'
      const pubs = [...channel.publications] //такая хрень, чтобы не мутировать channel, а то vue ругается на бесконечный рендер
      const lastAd =pubs.sort((a, b) => (a.id > b.id ? -1 : 1))[0]
      return Math.floor((new Date() - new Date(lastAd.planned_at)) / (1000 * 60 * 60 * 24)) + ' дн. назад'
    }

    return {
      selectedChannel, channel_id,
      props,
      favChannels,
      channels,
      getDaysSinceLastAd,
      change,
      mdiStar,
    }
  },
}
</script>

<style scoped>

</style>
