var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.adChannelCategories,"label":"Категория","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.filterByCat),callback:function ($$v) {_vm.filterByCat=$$v},expression:"filterByCat"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Название","clearable":"","outlined":"","hide-details":""},model:{value:(_vm.filterByName),callback:function ($$v) {_vm.filterByName=$$v},expression:"filterByName"}})],1)],1)],1)],1)],1),_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.channels,"headers":_vm.headers,"items-per-page":50,"sort-by":['created_at'],"sort-desc":[true],"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Каналы")]),_c('v-switch',{staticClass:"ml-4 mt-4",attrs:{"label":"Избранное"},model:{value:(_vm.showOnlyFavs),callback:function ($$v) {_vm.showOnlyFavs=$$v},expression:"showOnlyFavs"}},[_vm._v("Избранные")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.createChannel()}}},[_vm._v("Добавить")])],1)]},proxy:true},{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":("https://backend.bosfor-estate.ru/ad/channels/avatar?id=" + (item.id)),"max-height":"40px","max-width":"40px","contain":""}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.publications",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.publications.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.editItem = item; _vm.editDialog = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencil))])],1),_c('v-btn',{attrs:{"color":"success","icon":""},on:{"click":function($event){_vm.statsItem = item; _vm.statsDialog = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPoll))])],1)],1)]}},{key:"item.fav",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:{primary: _vm.favChannels.some(function (x){ return x===item.id; })},attrs:{"icon":""},on:{"click":function($event){return _vm.toggleFav(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiStar))])],1)]}},{key:"item.createPublication",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.createPublication(item)}}},[_vm._v("Купить рекламу")])]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"1000px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('ad-channel',{on:{"close":function($event){_vm.editDialog=false},"createPublication":_vm.createPublication},model:{value:(_vm.editItem),callback:function ($$v) {_vm.editItem=$$v},expression:"editItem"}})],1),_c('v-dialog',{attrs:{"width":"800px"},model:{value:(_vm.statsDialog),callback:function ($$v) {_vm.statsDialog=$$v},expression:"statsDialog"}},[_c('channel-stats',{attrs:{"channel":_vm.statsItem},on:{"close":function($event){_vm.statsDialog=false}}})],1),_c('v-dialog',{attrs:{"width":"90rem"},model:{value:(_vm.publicationDialog),callback:function ($$v) {_vm.publicationDialog=$$v},expression:"publicationDialog"}},[_c('edit-publication',{attrs:{"publication":_vm.publicationItem},on:{"close":function($event){_vm.publicationDialog=false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }