<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="post.name" label="Название" outlined hide-details></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="post.category"
              :items="categories"
              label='Категория'
              outlined
              clearable
            />
          </v-col>
          <v-col cols="5">
            <v-autocomplete
              v-model="post.channel_id"
              :items="channels"
              item-value="id"
              item-text="name"
              outlined clearable
              hide-details
              label="Канал"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <div class="text-h6 pt-3 text-center align-center justify-center">ИЛИ</div>
          </v-col>
          <v-col cols="5">
            <v-autocomplete
              v-model="post.bot_id"
              :items="bots"
              item-value="id"
              item-text="name"
              outlined clearable
              hide-details
              label="Бот"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <editor v-model="editorText"></editor>
          </v-col>
          <v-col cols="12">
            <v-btn v-for="(button, i) in post.buttons" :key="i" color="info" class="mx-2" @click="buttonToEdit=button; buttonDialog=true">
              {{ button.text }}
            </v-btn>
          </v-col>
          <v-col v-if="post.photos" cols="12">
            <ad-post-image
              v-for="image in post.photos"
              :key="image.id"
              :image="image"
              @deletePhoto="deletePhoto(image.id)"
            ></ad-post-image>
          </v-col>
          <v-col>
            <v-file-input
              id="uploader"
              v-model="photos"
              class="d-none"
              label="Фотографии"
              accept="image/*"
              truncate-length="15"
              multiple
              @change="uploadPhotos()"
            />
            <v-btn
              color="primary"
              class="mx-2"
              :loading="photosLoading"
              @click="selectPhoto()"
            >
              <v-icon left>
                {{ mdiImage }}
              </v-icon>
              Добавить фото
            </v-btn>
            <v-btn color="primary" class="mx-2" @click="editButton(0)">
              Добавить кнопку
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="post.comment" label="Комментарий" outlined />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-toolbar flat>
          <v-btn class="mx-2" :disabled="!canSave" :color="post.id ? 'primary' : 'success'" v-html="post.id ? 'Сохранить' : 'Создать'" @click="save()"></v-btn>
          <v-btn v-if="post.id" class="mx-2" color="success" @click="createPublication()">Закуп</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="post.id" color="error" @click="post.is_active = 0; save()"><v-icon class="mr-3">{{ mdiTrashCan }}</v-icon> Удалить</v-btn>
      </v-toolbar>
      </v-card-actions>
      <v-dialog v-model="buttonDialog" width="600px">
        <edit-button :button="buttonToEdit" @deleteButton="deleteButton" @close="buttonDialog=false"></edit-button>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { defineComponent, ref, computed } from '@vue/composition-api'
import { mdiImage, mdiTrashCan } from '@mdi/js'
import httpClient from '@/services/http.service'
import store from '@/store'
import Editor from '@/components/Editor.vue'
import AdPostImage from '@/views/ads/posts/AdPostImage.vue'
import EditButton from './EditButton.vue'

export default defineComponent({
  name: 'AdPost',
  components:{Editor, AdPostImage, EditButton},
  props: {value: {type: Object, default: () => {}}},
  mounted(){
    console.log('adpost mounted!', this.value)
  },
  watch:{
    'value.post': function (newValue) {
      this.editorText = newValue
    }
  },
  setup(props, {emit}) {
    const buttonToEdit = ref({})
    const buttonDialog = ref(false)
    const photos = ref()
    const photosLoading = ref(false)
    const saveLoading = ref(false)
    const post = useVModel(props, 'value', emit)
    const channels = computed(() => store.state.channels)
    const editorText = computed(
      {
        get: () => props.value.post,
        set(v){
          post.value.post = v
        }
      }
    )

    const paymentMethods = computed(() => store.getters.paymentMethods)
    const canSave = computed(()=> post.value.post && post.value.name)
    const categories = computed(() => store.getters.adPostCategories)
    const bots = computed(() => store.getters.bots)

    const save = () =>{
      saveLoading.value = true
      post.value.post = clearHtml(post.value.post)
      const method = post.value.id ? 'put' : 'post'
      const url = post.value.id ? '/ad/posts/'+post.value.id : '/ad/posts'
      httpClient[method](url, post.value).then(({data}) => {
        httpClient.post(`ad/posts/link-photos?post_id=${data.id}`, post.value.photos).then(() => {
          reloadPost(data.id)
          saveLoading.value = false
          emit('close')
        })
        saveLoading.value = false
      })
    }

    const reloadPost = id =>{
      httpClient.get(`ad/posts/${id}?expand=photos,publications`).then(({data})=>{
        store.dispatch('add', {mutation: 'updateAdPost', payload: data})
      })
    }

    const editorUpdate = value =>{
      post.value.post = value
    }

    const selectPhoto = () =>{
      // photos.value.click()
      const fileUpload = document.getElementById('uploader')
      if (fileUpload != null) {
        fileUpload.click()
      }
    }

    //загрузка фото(к). бэк сохранит у себя, загрузит в телегу, и вернёт запись из tg_photos
    const uploadPhotos = () =>{
      console.log('photos:', photos.value)
      if (photos.value.length === 0) {return }
      photosLoading.value = true
      const formData = new FormData()
      photos.value.forEach((x, i) => {
        formData.append(`photos[${i}]`, x)
      })
      httpClient.post('ad/posts/upload-photo', formData).then(({ data }) => {
        if (!post.value.photos){
          post.value.photos = []
        }
        data.forEach(x=>{
          post.value.photos.push(x)
        })
        photos.value = []
        photosLoading.value = false
      })
    }

    const deletePhoto = id =>{
      httpClient.get(`ad/posts/delete-photo?id=${id}`).then(()=>{
        post.value.photos.splice(post.value.photos.findIndex(x=>x.id===id), 1)
      })
    }

    const editButton = (i=0) =>{
      if (!post.value.buttons){
        post.value.buttons = []
      }
      if (post.value.buttons.length === 0){
        post.value.buttons.push({})
      } else if (i===0){
        post.value.buttons.push({})
        i = post.value.buttons.length-1
      }
      buttonToEdit.value = post.value.buttons[i]
      buttonDialog.value = true
    }

    const deleteButton = name =>{
      post.value.buttons.splice(post.value.buttons.findIndex(x=>x.name===name), 1)
      buttonDialog.value = false
    }

    const createPublication = () =>{
      emit('createPublication', post.value)
    }

    const clearHtml = v =>{
      // v = _.trim(v)
      v = v.replace(/<p[^>]*>/g, '<br>')
      v = v.replace(/<\/?p[^>]*>/g, '<br>')
      v = v.replace(/&nbsp;/g, '')
      if (v.length > 3){
        // заодно удалим первые и последние <br>
        if (v.substring(0, 4) === '<br>') {
          v = v.replace(/<br[^>]*>/, '')
        }
        if (v.substring(v.length - 4) === '<br>'){
          v = v.substring(0, v.length-4)
        }
      }
      return v
    }

    return{
      paymentMethods,
      channels, categories, bots,
      canSave,
      post, editorUpdate, editorText,
      buttonDialog, buttonToEdit,
      save,
      uploadPhotos, deletePhoto, selectPhoto,
      editButton, deleteButton,
      createPublication,
      photos,
      photosLoading,
      mdiImage, mdiTrashCan,
    }
  },
})
</script>

<style scoped>
.ps {
  height: 800px;
}
</style>
