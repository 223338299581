<template>
	<div v-if="editor">
				<v-row dense>
					<v-col cols="12">
						<div class="float-left">
							<v-btn class="ma-2" color="primary" :outlined="!editor.isActive('bold')" @click="editor.chain().focus().toggleBold().run()">
								<v-icon>{{icons.mdiFormatBold}}</v-icon>
							</v-btn>
							<v-btn class="ma-2" color="primary" :outlined="!editor.isActive('italic')" @click="editor.chain().focus().toggleItalic().run()">
								<v-icon>{{icons.mdiFormatItalic}}</v-icon>
							</v-btn>
							<v-btn class="ma-2" color="primary" :outlined="!editor.isActive('strike')" @click="editor.chain().focus().toggleStrike().run()">
								<v-icon>{{icons.mdiFormatStrikethrough}}</v-icon>
							</v-btn>
							<v-btn class="ma-2" color="primary" :outlined="!editor.isActive('code')" @click="editor.chain().focus().toggleCode().run()">
								<v-icon>{{icons.mdiCodeBrackets}}</v-icon>
							</v-btn>
							<v-btn class="ma-2" :outlined="!editor.isActive('link')" color="primary" @click="setCasualLink()">
								<v-icon>{{icons.mdiLink}}</v-icon>
							</v-btn>
              <v-btn class="ma-2" :outlined="!editor.isActive('link')" color="primary" @click="setAdLink()">
                <v-icon>{{icons.mdiMagicStaff}}</v-icon>
              </v-btn>
            </div>
          </v-col>
				</v-row>
			<v-spacer></v-spacer>
			<div id="editor_element">
        <editor-content :editor="editor" style="" />
      </div>
	</div>
</template>

<script>
import {mdiFormatBold, mdiFormatItalic, mdiFormatStrikethrough, mdiCodeBrackets, mdiAutorenew, mdiLockOutline, mdiCreation, mdiLink, mdiMagicStaff } from '@mdi/js'
// import _ from 'lodash'
import { Editor, EditorContent } from '@tiptap/vue-2'
// import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
// import Bold from '@tiptap/extension-bold'
// import Italic from '@tiptap/extension-italic'
// import Strike from '@tiptap/extension-strike'
// import History from '@tiptap/extension-history'
// import Document from '@tiptap/extension-document'
// import Text from '@tiptap/extension-text'
// import Paragraph from '@tiptap/extension-paragraph'
import HardBreak from '@tiptap/extension-hard-break'
// import Code from '@tiptap/extension-code'
import StarterKit from '@tiptap/starter-kit'
import { Paragraph, Bold, Italic, Strike, History, Document, Text, Code } from 'tiptap-extensions'
import colors from 'vuetify/lib/util/colors'

export default {
  components: { EditorContent},
  props: {
    value: {type: String, default: ''},
  },
  data() {
    return {
      icons: {mdiFormatBold, mdiFormatItalic, mdiFormatStrikethrough, mdiCodeBrackets, mdiAutorenew, mdiLockOutline, mdiCreation, mdiLink, mdiMagicStaff },
      colors,
      toggle_multiple: null,
      editor: null,
      linksDialog: false,
      lengthDialog: false,
    }
  },

  watch: {
    textLength(v){
      this.$emit('update:textLength', v)
    },
    value(value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      useBuiltInExtensions: false,
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline']
        ]
      },
      theme: 'snow',
      formats: ['bold', 'underline', 'italic'],
      editorProps: {
        attributes: {
          class: 'editor',
        },
      },
      extensions: [
        Document,
        Bold,
        Strike,
        Italic,
        Text,
        Paragraph,
        HardBreak.extend({
          addKeyboardShortcuts() {
            return {
              // ёбаный типтап не хочет работать без параграфа, а он нам нахуй не нужон. поэтому меняем действие: теперь на энтер будет делаться новая строка.
              // плюс при каждом обновлении текста в редакторе теги <p> будут меняться на <br> (clearHtml())
              Enter: () => this.editor.commands.setHardBreak()
            }
          }
        }),
        StarterKit,
        Code,
        Link.configure({
          openOnClick: false,
          HTMLAttributes: { target: undefined, rel: undefined }
        }),
        History,
      ],
      content: this.value,
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      },
    })
    console.log('mounted! set text:', this.value)
    this.setText(this.value)
  },
  methods:{
    // clearHtml(v){
    //   // v = _.trim(v)
    //   v = v.replace(/<p[^>]*>/g, '<br>')
    //   v = v.replace(/<\/?p[^>]*>/g, '<br>')
    //   v = v.replace(/&nbsp;/g, '')
    //   if (v.length > 3){
    //     // заодно удалим первые и последние <br>
    //     if (v.substring(0, 4) === '<br>') {
    //       v = v.replace(/<br[^>]*>/, '')
    //     }
    //     if (v.substring(v.length - 4) === '<br>'){
    //       v = v.substring(0, v.length-4)
    //     }
    //   }
    //   return v
    // },
    // reload(){
    //   this.setText(this.value)
    // },
    addLink(i){
      this.editor.commands.setLink({ href: '{{URL_' + i + '}}', rel: undefined, target: undefined})
      this.linksDialog = false
    },
    setCasualLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)
      // cancelled
      if (url === null) {
        return
      }
      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()
        return
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    setAdLink() {
      if (this.editor.isActive('link')){
        console.log('active link!')
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()
      }else{
        this.editor.commands.setLink({ href: '{{ADLINK}}', rel: undefined, target: undefined})
      }
    },
    setText(text){
      this.editor.commands.setContent(text, false)
    }
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>
<style>
  .editor{
    border: 1px solid;
    padding: 10px;
  }
</style>
