<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field v-model="channel.link" outlined label="Ссылка" :loading="tgLinkLoading" @keydown.enter="checkTgLink()" hide-details></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="channel.name" outlined label="Название" hide-details></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-combobox
              v-model="channel.category"
              :items="channelCategories"
              label="Категория"
              outlined hide-details
            ></v-combobox>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="channel.tgstat_link"
              label="Ссылка на TGStat"
              outlined hide-details
            >
              <template v-slot:append-outer>
                <v-btn v-if="channel.tgstat_link" :href="channel.tgstat_link" target="_blank" icon>
                  <v-icon class="align-self-center">{{ mdiMagnify }}</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="channel.telemetr_link"
              label="Ссылка на Telemetr"
              outlined hide-details
            >
              <template v-slot:append-outer>
                <v-btn v-if="channel.telemetr_link" :href="channel.telemetr_link" target="_blank" icon>
                  <v-icon class="align-self-center">{{ mdiMagnify }}</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-checkbox v-model="channel.is_bot" label="Это бот" :true-value="1" :false-value="0"></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="channel.description"
              rows="4"
              :prepend-icon="mdiComment"
              label="Описание"
              clearable
              outlined
            ></v-textarea>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="channel.price" type="number" label="Цена поста" outlined></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="channel.participants" type="number" :loading="refreshViewsLoading" outlined label="Кол-во участников"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="channel.avg_views" type="number" outlined label="Охват" :prepend-icon="mdiRefresh" :loading="refreshViewsLoading" @click:prepend="refreshViews"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="channel.avg_forwards" type="number" outlined label="Репосты" :loading="refreshViewsLoading"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="channel.admin_contact" outlined label="Контакт админа" hide-details></v-text-field>
            <v-btn
              v-if="channel.admin_contact"
              :href="'https://t.me/'+channel.admin_contact.replace('@', '')"
              target="_blank"
              color="primary"
              text small
            >
              Написать админу
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-combobox v-model="channel.payment_method" :items="paymentMethods" outlined label="Способ оплаты"></v-combobox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-toolbar flat>
          <v-btn :disabled="!canSave" :loading="saveLoading" @click="save()" :color="channel.id === 0 ? 'success' : 'primary'" v-html="channel.id === 0 ? 'Создать' : 'Сохранить'"></v-btn>
          <v-btn class="mx-2" v-if="channel.id" color="success" @click="createPublication()">Купить рекламу</v-btn>
          <v-spacer inset />
          <v-btn :loading="deleteLoading" color="error" @click="deleteChannel()">Удалить</v-btn>
        </v-toolbar>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import { mdiComment, mdiRefresh, mdiMagnify } from '@mdi/js'
import {useVModel} from '@vueuse/core'
import httpClient from '@/services/http.service'
import store from '@/store'

export default {
  name: 'AdChannel',
  props:{
    value: {type: Object, default: () => {}}
  },
  setup(props, {emit}){
    const channel = useVModel(props, 'value', emit)
    const tgLinkLoading = ref(false)
    const refreshViewsLoading = ref(false)
    const saveLoading = ref(false)
    const deleteLoading = ref(false)

    const paymentMethods = computed(() => store.getters.paymentMethods)
    const channelCategories = computed(() => store.getters.adChannelCategories)

    const save = () =>{
      saveLoading.value = true
      let url = channel.value.id ? `ad/channels/${channel.value.id}` : 'ad/channels'
      url += '?expand=publications'
      const method = channel.value.id ? 'put' : 'post'
      httpClient[method](url, channel.value).then(({data}) => {
        store.dispatch('add', {mutation: 'updateAdChannel', payload: data})
        saveLoading.value = false
        deleteLoading.value = false
        emit('close')
      })
    }

    const deleteChannel = () =>{
      channel.value.is_active = 0
      deleteLoading.value = true
      save()
    }

    const checkTgLink = () =>{
      tgLinkLoading.value = true
      httpClient.post('telegram/check-link', {link: channel.value.link}).then(({data})=>{
        if (data.success){
          channel.value.name = data.name
          channel.value.description = data.description
          channel.value.participants = data.participants
          channel.value.avg_views = data.avg_views
          channel.value.tg_id = data.tg_id
          channel.value.avg_forwards = data.avg_forwards
        }
        console.log('invite link:', data)
        tgLinkLoading.value = false
      })
    }

    const refreshViews = () =>{
      refreshViewsLoading.value = true
      httpClient.get(`ad/channels/refresh-views?id=${channel.value.id}`).then(({data})=>{
        channel.value.participants = data.participants
        channel.value.avg_forwards = data.forwards
        channel.value.avg_views = data.views
        refreshViewsLoading.value = false
      })
    }

    const createPublication = () =>{
      emit('createPublication', channel.value.id)
    }

    const canSave = computed(()=>channel.value.link && channel.value.name)

    return{
      paymentMethods, channelCategories,
      canSave,
      channel,
      save, checkTgLink, refreshViews, createPublication, deleteChannel,
      tgLinkLoading, refreshViewsLoading, saveLoading, deleteLoading,
      mdiComment, mdiRefresh, mdiMagnify,
    }
  },
}
</script>
