<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="publicationLocal.ad_post_id"
                  :items="adPosts"
                  item-text="name"
                  item-value="id"
                  label="Пост"
                  :append-icon="mdiPencil"
                  hide-details
                  outlined
                  @click:append="editAdPost()"
                  @change="postSelected()"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar v-if="favPosts.some(x=>x===item.id)"><v-icon small color="primary">{{ mdiStar }}</v-icon></v-list-item-avatar>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="publicationLocal.channel_id"
                  :items="channels"
                  item-text="name"
                  item-value="id"
                  label="ИЛИ канал"
                  outlined hide-details clearable
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="publicationLocal.bot_id"
                  :items="bots"
                  item-text="name"
                  item-value="id"
                  label="ИЛИ бот"
                  outlined hide-details clearable
                />
              </v-col>
              <v-col cols="12">
                <AdChannelSelector
                  :value.sync="publicationLocal.ad_channel_id"
                  :ad-channel-id="publicationLocal.ad_channel_id"
                  :items="adChannels"
                  item-text="name"
                  item-value="id"
                  label="Источник"
                  :clearable="false"
                  outlined hide-details
                ></AdChannelSelector>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="publicationLocal.ad_slot_id"
                  :items="slots"
                  item-text="planned_at"
                  item-value="id"
                  label="Слот"
                  outlined hide-details
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="publicationLocal.price" :key="priceKey" outlined label="Цена" hide-details></v-text-field>
                <div v-if="latestPrice" class="text--secondary">В последний раз цена была <v-btn color="primary" @click="setLatestPrice()">{{ latestPrice }}</v-btn></div>
              </v-col>

            </v-row>
          </v-col>
          <v-col cols="3">
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-date-picker :key="dateKey" v-model="publicationLocal.planned_at" :first-day-of-week="1" landscape no-title />
              </v-col>
              <v-col cols="6">
                <v-btn v-for="(time, t) in lastTime" :key="time" color="info" text @click="publicationLocal.planned_at_time = time; timeKey++">{{ time }}</v-btn>
              </v-col>
              <v-col cols="6">
                <v-text-field :key="timeKey" v-model="publicationLocal.planned_at_time" type="time" label="Время" outlined />
              </v-col>
              <v-col cols="12">
                <v-combobox
                  v-model="publicationLocal.payment_method"
                  :items="paymentMethods"
                  outlined hide-details
                  label="Способ оплаты"
                ></v-combobox>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="publicationLocal.create_join_request" :true-value="1" :false-value="0" label="Заявка на вступление" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-card>
              <div v-if="publicationsAtDate.length === 0">
                В этот день никаких публикаций
              </div>
              <div v-else>
                <div>
                  Публикации в этот день:
                </div>
                <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Источник</th>
                      <th>Пост</th>
                      <th>Время</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(pub, i) in publicationsAtDate" :key="pub.id">
                      <td>{{ getAdChannelName(pub.ad_channel_id) }}</td>
                      <td>{{ getAdPostName(pub.ad_post_id) }}</td>
                      <td>{{ pub.planned_at_time }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="saveLoading" :disabled="!canSave" @click="save()">Запланировать</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="postDialog" width="800px">
      <perfect-scrollbar :options="{suppressScrollX: true}">
        <ad-post v-model="postItem" @close="postDialog=false"></ad-post>
      </perfect-scrollbar>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from 'moment'
import { mdiPencil, mdiStar } from '@mdi/js'
import { computed, defineComponent, ref, onMounted, set } from '@vue/composition-api'
import { useVModel } from '@vueuse/core'
import store from '@/store'
import AdPost from '../posts/AdPost.vue'
import httpClient from '@/services/http.service'
import AdChannelSelector from "@/components/AdChannelSelector";

export default defineComponent({
  name: 'EditPublication',
  components:{
    AdPost, AdChannelSelector,
  },
  props:{
    publication: {
      type: Object,
      default: () => {},
    }
  },
  setup(props, {emit}) {
    const priceKey = ref(0)
    const timeKey = ref(0)
    const dateKey = ref(0)
    const postItem = ref({})
    const postDialog = ref(false)
    const saveLoading = ref(false)
    const paymentMethods = computed(()=>store.getters.paymentMethods)
    const latestPrice = computed(()=>{
      if (!publicationLocal.value.ad_channel_id) return 0
      const latest = store.state.adPublications.filter(x=>x.ad_channel_id===publicationLocal.value.ad_channel_id)
      if (latest.length){
        return latest.sort((a, b) => (a.id > b.id ? -1 : 1))[0].price
      }
      return 1
    })

    const channels = computed(()=>store.state.channels)
    const bots = computed(()=>store.getters.bots)
    const adChannels = computed(()=>store.state.adChannels)
    const allAdPosts = computed(()=>store.state.adPosts)
    const favChannels = computed(()=>store.state.favChannels)
    const favPosts = computed(()=>store.state.favPosts)
    const adPosts = computed(() => allAdPosts.value.filter(x=>x.is_active).sort((a, b) => (favPosts.value.some(x=>x===a.id) ? -1 : 1)))
    const canSave = computed(()=>{
      return (
        publicationLocal.value.price > 0
        && publicationLocal.value.ad_channel_id > 0
        && publicationLocal.value.ad_post_id > 0
        && publicationLocal.value.planned_at !== null
        && publicationLocal.value.planned_at_time !== null
      )
    })
    const slots = computed(()=>store.state.adSlots.filter(x=>x.ad_channel_id===publicationLocal.value.ad_channel_id))
    const publicationsAtDate = computed(()=>store.state.adPublications.filter(x=>moment(x.planned_at).format('YYYY-MM-DD')===moment(publicationLocal.value.planned_at).format('YYYY-MM-DD')))
    const lastTime = computed(()=>{
      const times = []
      if (publicationsAtDate.value.length === 0) {
        times.push('12:00')
        times.push('15:00')
        times.push('18:00')
        return times
      }
      const last = publicationsAtDate.value.filter(x=>x.planned_at_time !== null).sort((a, b) => (a.planned_at_time > b.planned_at_time ? -1 : 1))[0]
      console.log('last: ', last)
      if (last && last.planned_at_time){
        times.push(moment(last.planned_at_time, ['H:m:s']).add(5, 'minutes').format('HH:mm'))
        times.push(moment(last.planned_at_time, ['H:m:s']).add(10, 'minutes').format('HH:mm'))
        times.push(moment(last.planned_at_time, ['H:m:s']).add(15, 'minutes').format('HH:mm'))
      }

      return times
    })
    const publicationLocal = useVModel(props, 'publication', emit)

    if (!publicationLocal.value.create_join_request){
      publicationLocal.value.create_join_request = 1
    }

    onMounted(()=>{
      if (!publicationLocal.value.create_join_request){
        publicationLocal.value.create_join_request = 1
      }
      if (publicationLocal.value.ad_channel_id){
        publicationLocal.value.price = adChannels.value.find(x=>x.id===publicationLocal.value.ad_channel_id).price
        priceKey.value+=1
      }
      if (!publicationLocal.value.planned_at){
        set(publicationLocal.value, 'planned_at', moment().format('YYYY-MM-DD'))
      }
      if (!publicationLocal.value.planned_at_time){
        set(publicationLocal.value, 'planned_at_time', '12:00')
      }
    })
    // publicationLocal.value.price = 0

    const editAdPost = () =>{
      if (!publicationLocal.value.ad_post_id){
        return
      }
      postItem.value = adPosts.value.find(x=>x.id===publicationLocal.value.ad_post_id)
      postDialog.value = true
    }

    const save = () =>{
      if (publicationLocal.value.bot_id){
        if (publicationLocal.value.channel_id){
          alert('ИЛИ канал ИЛИ бот!!!!!')
          return
        }
        publicationLocal.value.channel_id = 0
      }
      if (publicationLocal.value.channel_id){
        if (publicationLocal.value.bot_id){
          alert('ИЛИ канал ИЛИ бот!!!!!')
          return
        }
        publicationLocal.value.bot_id = 0
      }
      if (!publicationLocal.value.bot_id && !publicationLocal.value.channel_id){
        alert('Нужно выбрать на какой канал или бот покупается реклама')
      }
      saveLoading.value = true
      const verb = publicationLocal.value.id ? 'put' : 'post'
      httpClient[verb]('ad/publications', publicationLocal.value).then(({data})=>{
        saveLoading.value = false
        if (data.id){
          console.log('add to store:', data)
          store.dispatch('add', {mutation: 'updateAdPublication', payload: data})
          emit('close')
        }
        console.log('result: ', data)
      })
    }

    const postSelected = () =>{
      publicationLocal.value.channel_id = allAdPosts.value.find(x=>x.id===publicationLocal.value.ad_post_id).channel_id
    }

    const getAdChannelName = id =>{
      const adChannel = adChannels.value.find(x=>x.id===id)
      if (adChannel){
        return adChannel.name
      }
      return ''
    }

    const getAdPostName = id =>{
      const adPost = allAdPosts.value.find(x=>x.id===id)
      if (adPost){
        return adPost.name
      }
      return ''
    }

    const setLatestPrice = () =>{
      console.log(JSON.parse(JSON.stringify(publicationLocal.value)))
      publicationLocal.value.price = latestPrice.value
      priceKey.value+=1
      console.log(JSON.parse(JSON.stringify(publicationLocal.value)))
    }

    return {
      slots, bots,
      priceKey, timeKey, dateKey,
      saveLoading,
      paymentMethods,
      editAdPost, save, postSelected, setLatestPrice,
      getAdChannelName, getAdPostName,
      canSave,
      postDialog, postItem,
      channels, adChannels, adPosts,
      publicationLocal,
      favPosts, favChannels,
      latestPrice, publicationsAtDate, lastTime,
      mdiPencil, mdiStar,
    }
  },
})
</script>
<style scoped>
.ps{
  height: 800px;
}
</style>
