<template>
  <v-container>
    <v-row></v-row>
    <v-card>
      <v-card-title>{{ channel.name }}</v-card-title>
      <v-card-text>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AdChannelStats',
  props: {
    channel: {
      type: Object,
      default: () => {},
    }
  },
  setup() {
    return{
    }
  },
})
</script>
