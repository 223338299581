<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-text-field v-model="button.text" outlined label="Текст"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save()" color="primary">
          Сохранить
        </v-btn>
        <v-btn color="error" @click="$emit('deleteButton', button.text)">Удалить</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'EditButton',
  props:{
    button: {type: Object, default: () => {}},
  },
  setup(props, {emit}) {
    const save = () =>{
      emit('close')
    }
    return{
      save
    }
  },
})
</script>
