<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 12 : 2"
        :class="[{ 'on-hover': hover }, 'd-flex']"
      >
        <v-img
          :src="`https://backend.bosfor-estate.ru/ad/posts/image?id=${image.id}`"
          :class="[{ 'on-hover': hover }, 'd-flex']"
          max-height="300px"
        >
          <div class="align-self-center justify-center">
            <v-btn
              :class="[{ 'show-btns': hover }, 'align-self-center', 'justify-center']"
              :color="transparent"
              x-large
              icon
              @click="deletePhoto()"
            >
              <v-icon
                x-large
              >
                {{ mdiDelete }}
              </v-icon>
            </v-btn>
          </div>
        </v-img>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { mdiDelete } from '@mdi/js'

export default {
  name: 'AdPostImage',
  props:{
    image:{type: Object, default: () => {}}
  },
  setup(props, {emit}){
    const transparent = 'rgba(255, 0, 0, 0)'

    const deletePhoto = () =>{
      emit('deletePhoto')
    }

    return {
      transparent,
      mdiDelete,
      deletePhoto,
    }
  }
}
</script>

<style>
.show-btns {
  color: rgb(255, 0, 0, 1) !important;
}
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 1;
}
.v-card.on-hover {
  color: rgb(255, 0, 0, 0.5) !important;
}
</style>
