<template>
  <v-container>
    <v-row>
      <v-col cols='12'>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="filterByCat"
                  :items="adChannelCategories"
                  label="Категория"
                  outlined
                  clearable
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols='3'>
                <v-text-field v-model='filterByName' label='Название' clearable outlined hide-details />
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-data-table
          :items="channels"
          :headers="headers"
          :items-per-page="50"
          :sort-by="['created_at']"
          :sort-desc="[true]"
          dense
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Каналы</v-toolbar-title>
              <v-switch v-model="showOnlyFavs" label="Избранное" class="ml-4 mt-4">Избранные</v-switch>

              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="ma-2" @click="createChannel()">Добавить</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.photo="{item}">
            <v-img :src="`https://backend.bosfor-estate.ru/ad/channels/avatar?id=${item.id}`" max-height="40px" max-width="40px" contain></v-img>
          </template>
          <template v-slot:item.created_at="{item}">
            {{formatDate(item.created_at)}}
          </template>
          <template v-slot:item.publications="{item}">
            {{ item.publications.length }}
          </template>
          <template v-slot:item.actions="{item}">
            <div class="d-flex">
              <v-btn color="primary" icon @click="editItem = item; editDialog = true">
                <v-icon>{{ mdiPencil }}</v-icon>
              </v-btn>
              <v-btn color="success" icon @click="statsItem = item; statsDialog = true">
                <v-icon>{{ mdiPoll }}</v-icon>
              </v-btn>
            </div>

          </template>
          <template v-slot:item.fav="{item}">
            <v-btn :class="{primary: favChannels.some(x=>x===item.id)}" icon @click="toggleFav(item)"><v-icon>{{ mdiStar }}</v-icon></v-btn>
          </template>
          <template v-slot:item.createPublication="{item}">
            <v-btn color="primary" text @click="createPublication(item)">Купить рекламу</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="editDialog" width="1000px">
      <ad-channel v-model="editItem" @close="editDialog=false" @createPublication="createPublication"></ad-channel>
    </v-dialog>
    <v-dialog v-model="statsDialog" width="800px">
      <channel-stats :channel="statsItem" @close="statsDialog=false"></channel-stats>
    </v-dialog>
    <v-dialog v-model="publicationDialog" width="90rem">
      <!-- <perfect-scrollbar :options="{suppressScrollX: true}"> -->
        <edit-publication :publication="publicationItem" @close="publicationDialog=false"></edit-publication>
      <!-- </perfect-scrollbar> -->
    </v-dialog>
  </v-container>
</template>>

<script>
import { ref, computed } from '@vue/composition-api'
import { mdiPencil, mdiStar, mdiPoll } from '@mdi/js'
import store from '@/store'
import AdChannel from './AdChannel.vue'
import ChannelStats from './ChannelStats.vue'
import EditPublication from '../publications/EditPublication.vue'
import moment from "moment";

export default {
  name: 'AdChannels',
  components: {
    AdChannel, ChannelStats, EditPublication,
  },
  setup(){
    const filterByCat = ref('')
    const filterByName = ref('')
    const editDialog = ref(false)
    const editItem = ref({id: 0, name: ''})
    const statsItem = ref({})
    const statsDialog = ref(false)
    const publicationDialog = ref(false)
    const publicationItem = ref({})
    const allChannels = computed(()=>store.getters.adChannels)
    const favChannels = computed(()=>store.state.favChannels)
    const showOnlyFavs = ref(false)
    // const channels = computed(()=>(showOnlyFavs.value ? allChannels.value.filter(x => favChannels.value.some(y => y===x.id)) : allChannels.value))
    const channels = computed(()=>{
      let pubs = allChannels.value
      if (showOnlyFavs.value) {pubs = pubs.filter(x => favChannels.value.some(y => y===x.id))}
      if (filterByName.value) {pubs = pubs.filter(x=>x.name.toLowerCase().includes(filterByName.value.toLowerCase()))}
      if (filterByCat.value) {pubs = pubs.filter(x=>x.category===filterByCat.value)}
      return pubs
    })
    const adChannelCategories = computed(()=>store.getters.adChannelCategories)

    const headers = ref([
      {text: '', value: 'photo', width: '40px', sortable: false},
      {text: 'Название', value: 'name'},
      {text: 'Дата добавления', value: 'created_at', width: '150px', sortable: true},
      {text: 'Публикаций', value: 'publications'},
      {text: 'Охват', value: 'avg_views'},
      {text: 'Цена', value: 'price'},
      {text: 'Подписчики', value: 'subscribers'},
      {text: '', value: 'actions', sortable: false},
      {text: '', value: 'createPublication', sortable: false},
      {text: '', value: 'fav', sortable: false},

    ])

    const createChannel = () =>{
      editItem.value = {id: 0, name: ''}
      editDialog.value = true
    }

    const toggleFav = item =>{
      store.dispatch('add', {mutation: 'toggleFavChannel', payload: item.id})
    }

    const createPublication = item =>{
      editDialog.value = false //ну если вдруг он был открыт
      publicationItem.value = {id: 0, ad_channel_id: item.ad_channel_id, price: 0, create_join_request: 1, bot_id: item.bot_id}
      // publicationItem.value.id = 0
      // publicationItem.value.ad_channel_id = ad_channel_id
      // publicationItem.value.price = 0
      // publicationItem.value.create_join_request = 1
      // publicationItem.value.bot_id = null
      publicationDialog.value = true
    }

    const formatDate = date => moment(date).format('DD.MM.YY')

    return{
      adChannelCategories,
      channels, headers, favChannels,
      editDialog, editItem,
      statsDialog, statsItem,
      publicationDialog, publicationItem,
      showOnlyFavs,
      filterByCat, filterByName,
      createChannel, toggleFav, createPublication, formatDate,
      mdiPencil, mdiStar, mdiPoll,
    }
  }
}
</script>

<style scoped>
.ps{
  height: 800px;
}

.v-select.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  transform: rotate(0);
}
</style>
